import React from "react"
import Head from 'next/head'

type TSeo = {
  title?: string,
  description?: string
}

const SEO = ({ title, description}: TSeo) => {

  return (
    <>
      <Head>
        <title>{ title ? title.replace(/(<([^>]+)>)/gi, "") : 'StudioKast'}</title>
        <meta name="description" content={description ? description : ''}/>
        <meta property="og:title" content={title? title.replace(/(<([^>]+)>)/gi, "") : ''} />
        <meta property="og:description" content={description} />
       </Head>
    </>
  )
}

export default SEO
