import type { NextPage } from 'next'
import styled from 'styled-components'
import client from "../lib/graphql"

// import ContentBlock from '../components/base/ContentBlock'
// import ContentSlider from '../components/base/ContentSlider'
// import TextBlock from '../components/base/TextBlock'

import Layout from '../components/layouts/Index'
import { GET_HOME_CONTENT } from '../graphql/query'
import SEO from '../components/base/Seo'
import dynamic from 'next/dynamic'

type TPartner = {
  name: string,
  link: string
}

const DynamicContentBlock = dynamic(() => import('../components/base/ContentBlock'))

const DynamicTextBlock = dynamic(() => import('../components/base/TextBlock'))

const DynamicContentSlider = dynamic(() => import('../components/base/ContentSlider'))

const DynamicContentPartner = dynamic(() => import('../components/base/ContentPartner'))

const Home: NextPage<{ pageData: any, headerSlider: any }> = ( { pageData, headerSlider } ) => {

  return (
    <Layout heroSlider={headerSlider}>

      { pageData && <><PageWrap id="content">
            <div
              className={`section grid transition-all`}
            >
             
                <DynamicContentBlock
                  title={pageData.title ? pageData.title : ''}
                  content={pageData.lead_text ? pageData.lead_text : ''}
                  isPageTitle={true}
                />
             
            </div>

        { pageData.slider && <><div className='section'>
         
            <DynamicContentSlider
              contents={pageData.slider} 
            />
         
        </div></>}

        { pageData.content && <><div className='section grid'>
         
            <DynamicTextBlock contents={pageData.content} />
         
        </div></> }

        { pageData.more_content && 
          pageData.more_content.map((content: { title: string, partners: TPartner[] }, key:number) => (
            <div
              className={`section grid transition-all ${(key == pageData.more_content.length - 1) ? `spacing` : ``}`} key={key}
            >
              <DynamicContentPartner
                title={content.title ? content.title : ''}
                partners={content.partners ? content.partners : []}
              />
            </div>
          ))
        }

      </PageWrap></> }

      { pageData && <SEO title={pageData.title} description={pageData.lead_text}  /> }

    </Layout>
  )
}

export default Home;

export async function getStaticProps() {


  const { data: { studioKast } } = await client.query({
    query: GET_HOME_CONTENT,
  });

  return {
    props: {
      pageData: studioKast ? studioKast.data.attributes : null
    },
    revalidate: 10,
 };
}

const PageWrap = styled.div`
 height: auto;
 width: 100%;
 aspect-ratio: 1;
 box-sizing: border-box;
`

